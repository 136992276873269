// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { constant } from "../../config/config";

// const Blogs = () => {
//   const [bloglist, setBlogList] = useState([]);

//   useEffect(() => {
//     const fetchBlogList = async () => {
//       const token =
//         "84f0ff29ef29e2988056b0cec1c8c9c1d04547841f9e53635fd01947ec3cc3cef29ac8748fcfe4f8b083e5a65fe97effc147ea828115076805bf8ace58fc4f1a93f8e4757941e06f67394bfe9b212c6992d1381a87e775ee199b32725c9f33cec1494c4c769883e35356b3c41553408b27997db1be2a190ac4c7d40bae8f04d4";

//       try {
//         const response = await axios.get(
//           `${constant.apiUrl}/api/portfolios?populate=*`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setBlogList(response.data.data);
//         console.log(response.data.data);
//       } catch (error) {
//         console.log("Error fetching data:", error);
//       }
//     };

//     fetchBlogList();
//   }, []);

//   return (
//     <div className="container">
//       <section className="breadcrumb-area pt-175 pb-70">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="breadcrumb-content">
//                 <h2 className="title">Blogs</h2>
//                 <nav aria-label="breadcrumb">
//                   <ol className="breadcrumb">
//                     <li className="breadcrumb-item">
//                       <Link to="/">Home</Link>
//                     </li>
//                     <li className="breadcrumb-item active" aria-current="page">
//                       Blogs
//                     </li>
//                   </ol>
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="breadcrumb-shape-wrap">
//           <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
//           <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
//         </div>
//       </section>
//       <div className="blogs pt-40">
//         {bloglist.length > 0 ? (
//           bloglist.map((data) => {
//             const displayData = data.attributes?.portfolioImages?.data[0];
//             const imageUrl = displayData?.attributes?.url;
// console.log(imageUrl);
//             return (
//               <div className="row pb-4" key={data.id}>
//                 <div className="col-lg-4">
//                   {imageUrl ? (
//                     <img
//                       src={`${constant.apiUrl}${imageUrl}`}
//                       alt={data.attributes.Title || 'Blog Image'}
//                     />
//                   ) : (
//                     <div>No Image Available</div>
//                   )}
//                 </div>
//                 <div className="col-lg-8">
//                   <h3>{data.attributes.heading}</h3>
//                   <p>{data.attributes.description}</p>
//                   <Link to={`/blogs-detail?blogId=${data.id}`}>View More</Link>
//                 </div>
//               </div>
//             );
//           })
//         ) : (
//           <p>No Blog items found.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Blogs;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { constant } from "../../config/config";
import Shimmer from "./shimmer";
const Blogs = () => {
  const [bloglist, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogList = async () => {
      const token =
        "ff0e86a1e60669c61efca944bc5370545f073a4a2feffcbea9ea99210c81ecce0280c044d131ce5a4f2e4ef367c632d97cc0947e0284aa30c24ab64080b44bf0254f8f3f35702345f627b459626b0ad42f3ae8c9db7d2353b1277cd449ba06ec6184ca9011a53d622216965a76da25b935a2a50c0ce9660b063d1daee435af8c";

      try {
        const response = await axios.get(
          // `${constant.apiUrl}/api/portfolios?populate=*`,
          `${constant.apiUrl}/api/blogs?populate=*`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBlogList(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogList();
  }, []);

// console.log(
  // data.attributes.description ? data?.attributes?.RichTextmarkdown?.)
  return (
    <div className="container">
<Helmet>
<meta charSet="utf-8" />
<title>Blogs software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <section className="breadcrumb-area pt-175 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">Blogs</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blogs
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-shape-wrap">
          <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
          <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
        </div>
      </section>
      <div className="blogs pt-40">
        <div className="row pb-4">
          {loading ? (
            <div className="d-flex flex-column justify-center align-center" >
              <div className="d-flex flex-row justify-space-between gap-3 align-center">
              <Shimmer />
              <Shimmer />
              <Shimmer />
              </div>
              <br />
              <div className="d-flex flex-row justify-space-between gap-3 align-center">
              <Shimmer />
              <Shimmer />
              <Shimmer />
              </div>
            </div>
          ) : bloglist.length > 0 ? (
            bloglist.map((data) => {
              const displayData = data.attributes?.display?.data;

              const imageUrl = displayData?.attributes?.url;

              return (
                <div className="col-lg-4 col-md-6 col-sm-12 mb-5 " key={data.id}>
                  <div className="img">
                    {imageUrl ? (
                      <Link to={`/blogs-detail?blogId=${data.id}`}>
                      
                    <img
                        className="w-100 mb-3" style={{height:'230px',borderRadius:'20px'}}
                        src={`${constant.apiUrl}${imageUrl}`}
                        alt={data.attributes.Title || "Blog Image"}
                        />
                        </Link>
                    ) : (
                      <div className="pt-5" >
                        <img src="blog.png" alt="" />
                        No Image Available</div>
                    )}
                  </div>
                  <div className="content">
                  <h3>{data.attributes.title ? data.attributes.title.slice(0, 30) + "...." : "No Title Available"}</h3>
      <p>{data.attributes.RichTextmarkdown ? data?.attributes?.RichTextmarkdown?.slice(0, 80) + "...." : "No Description Available"}</p>
    
                    {/* <h3>{data.attributes.title.slice(0,30) + "...."}</h3>
                    <p>{data.attributes.description.slice(0, 80) + "...."}</p> */}
                    <Link className="usama px-4 py-2" to={`/blogs-detail?blogId=${data.id}`}>
                      View More
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Blog items found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
